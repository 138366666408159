import logo from "./Skin-dulge-white-logo.png";
import "./App.css";
import image from "./skindulge.png";
import { FaInstagramSquare , FaFacebookSquare  } from "react-icons/fa";
import mobileImg from "./skincare-mobile.png"
import React, { useState, useEffect } from "react";


function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024); 
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      className="relative h-screen w-full flex items-center justify-center bg-cover bg-center text-center px-5"
      style={{
        backgroundImage: `url(${isMobile ? mobileImg : image})`,
      }}    >
      {/* Overlay */}
      <div className="absolute top-0 right-0 bottom-0 left-0 bg-[#593e3e] opacity-45"></div>

      <div className="z-50 flex flex-col justify-center text-white w-full h-screen">
        <div className="w-full justify-center flex items-center">
          <img src={logo} alt="Skin Dulge" className="w-60 h-auto py-6" />

        </div>
        <h1 className="text-5xl">
          We are <b>Almost</b> there!
        </h1>
        <p>Stay tuned for something amazing!!!</p>

        <div className="mt-10 mb-5">
          <div className="shadow w-full bg-white mt-2 max-w-2xl mx-auto rounded-full">
            <div className="rounded-full bg-[#946c6c] text-xs leading-none text-center w-[75%] text-white py-1">
              75%
            </div>
          </div>
        </div>

        <div className="mt-6 flex text-white mx-auto gap-2 items-center">         
          <a href="https://www.instagram.com/skindulge.official/?igsh=Z2Jvdmg3NHVpbWw4&utm_source=qr" >
          <FaInstagramSquare className="text-white w-6 h-6"/>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61567982260595">
          <FaFacebookSquare className="text-white w-6 h-6"/>
          </a>        
      
        </div>
      </div>
    </div>
  );
}

export default App;
